/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sendEmail/sendEmailCapture/sendEmailContainer"/>
import * as React from "react";
import { useState, useEffect } from "react";

import { Button as ButtonProgress } from "@progress/kendo-react-buttons";
import { ModalDialog, FixedGridLayout, TextField, TextAreaField, AutoCompleteField, Button } from "@medius/ui-controls";

import { translate, getFormattedLabelTranslation } from "../../lib/globalization";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { error, success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

import { CompanyUserListItem, Recipient, CaptureEmailInfo, getCompanyUsers, validateEmail, sendEmail } from "./sendEmailContainerService";

interface SendEmailModalContainerProps {
    taskId: number,
    companyId: number,
    documentType: string,
    documentId: number,
    ccfInvoiceId: string
}

export default function SendEmailModalContainer({ taskId, companyId, documentType, documentId, ccfInvoiceId }: SendEmailModalContainerProps) {
    documentType = documentType?.split(",", 1)[0].trim();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const hasAccess = store.getState().accesses.sendInvoiceAsEmail;

    if (!hasAccess) {
        return (<></>);
    }

    return (
        <>
            <ButtonProgress data-testid="image-viewer-send-email-btn" icon="envelope" onClick={() => setIsModalOpen(true)}>
                {translate("#Core/email")}
            </ButtonProgress>
            <SendEmailModal
                isModalOpen={isModalOpen}
                documentType={documentType}
                documentId={documentId}
                taskId={taskId}
                companyId={companyId}
                ccfInvoiceId={ccfInvoiceId}
                handleOnClose={() => setIsModalOpen(false)} />
        </>
    );
}

interface SendEmailModalProps {
    isModalOpen: boolean;
    companyId: number;
    taskId: number;
    documentId: number
    documentType: string,
    ccfInvoiceId: string
    handleOnClose: () => void;
}

const SendEmailModal = ({ isModalOpen, companyId, taskId, documentId, documentType, ccfInvoiceId, handleOnClose }: SendEmailModalProps) => {
    const [companyUsers, setCompanyUsers] = useState<CompanyUserListItem[]>([]);
    const [companyReceiver, setCompanyReceiver] = useState<Recipient | null>(null);
    const [otherReceiver, setOtherReceiver] = useState<Recipient | null>(null);

    const [emailSubject, setEmailSubject] = useState<string>("");
    const [emailMessage, setEmailMessage] = useState<string>("");
    const [receiversCollection, setReceiversCollection] = useState<Recipient[]>([]);
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (isModalOpen) {
            getCompanyUsers(companyId).then(setCompanyUsers);
        } else {
            setReceiversCollection([]);
            setCompanyUsers([]);
            setCompanyReceiver(null);
            setOtherReceiver(null);
            setEmailSubject("");
            setEmailMessage("");
        }
    }, [isModalOpen, taskId, companyId]);

    useEffect(() =>
        setIsSendButtonDisabled(receiversCollection.length == 0 || emailSubject === "" || emailMessage === ""),
        [receiversCollection, emailSubject, emailMessage]);

    const handleCompanyAddButtonOnClick = () => addEmailToReceiversCollection(companyReceiver, true);
    const handleOthersAddButtonOnClick = () => addEmailToReceiversCollection(otherReceiver);
    const handleRemoveFromRecipients = (email: string) => setReceiversCollection(receiversCollection.filter(receiver => receiver.email !== email));
    const handleOnSend = () => {
        setIsSendButtonDisabled(true);
        const emailInfo = new CaptureEmailInfo(emailSubject, emailMessage, documentId, documentType, ccfInvoiceId);
        sendEmail(emailInfo, receiversCollection.map(receiver => receiver.email))
            .then(() => {
                success(translate("#Core/emailSentSuccessfully"));
                handleOnClose();
            })
            .catch(() => error(translate("#Core/emailSentUnsuccessfully")))
            .finally(() => setIsSendButtonDisabled(false));
    };

    const addEmailToReceiversCollection = (recipient: Recipient, showInvalidEmailForUsername: boolean = false): boolean => {
        const trimmedEmail = recipient?.email?.trim();
        if (isNullOrUndefined(trimmedEmail) || !validateEmail(trimmedEmail)) {
            showInvalidEmailForUsername ?
                error(getFormattedLabelTranslation("#Core/lackOfUserEmail_userName", [recipient.name])) :
                error(getFormattedLabelTranslation("#Core/invalidEmail_address", [recipient.email]));
            return false;
        }

        if (receiversCollection.find(receiver => receiver.email == trimmedEmail)) {
            error(translate("#Core/emailAlreadyAdded"));
            return false;
        }

        setReceiversCollection([...receiversCollection, new Recipient(recipient.name, trimmedEmail)]);
        return true;
    };

    return (
        <>
            <ModalDialog
                data-testid="image-viewer-send-email-modal"
                showHeader
                onRequestClose={handleOnClose}
                title={translate("#Core/emailHeader")} isOpen={isModalOpen}
                renderActions={() => SendEmailActions({ handleOnSend: handleOnSend, handleOnClose: handleOnClose, isDisabled: isSendButtonDisabled })}
                renderContent={() => {
                    return (
                        <>
                            <FixedGridLayout columns={1} horizontalAlignment="center">
                                <TextField
                                    data-testid="send-email-subject"
                                    fieldLabel={translate("#Core/emailSubject")}
                                    inputProps={{ onChange: ({ value }) => setEmailSubject(value) }}
                                    isRequired />
                                <TextAreaField
                                    data-testid="send-email-message"
                                    fieldLabel={translate("#Core/emailMessage")}
                                    inputProps={{ onChange: ({ value }) => setEmailMessage(value) }}
                                    isRequired />

                                <FixedGridLayout columns={2} horizontalAlignment="center">
                                    <SendEmailAddCompanyRecipients
                                        allItems={companyUsers}
                                        handleAutoCompleteFieldOnChange={setCompanyReceiver}
                                        handleAddButtonOnClick={handleCompanyAddButtonOnClick} />
                                </FixedGridLayout>

                                <FixedGridLayout columns={2} horizontalAlignment="center">
                                    <SendEmailAddOtherRecipients
                                        handleTextFieldOnChange={setOtherReceiver}
                                        handleAddButtonOnClick={handleOthersAddButtonOnClick} />
                                </FixedGridLayout>

                                <FixedGridLayout columns={2} horizontalAlignment="center">
                                    <SendEmailRecipients recipients={receiversCollection} onRemoveClick={handleRemoveFromRecipients} />
                                </FixedGridLayout>
                            </FixedGridLayout>
                        </>
                    );
                }}
            />
        </>
    );
};

interface SendEmailActionsProps {
    handleOnSend: () => void;
    handleOnClose: () => void;
    isDisabled: boolean
}

const SendEmailActions = ({ handleOnSend, handleOnClose, isDisabled }: SendEmailActionsProps) =>
    <>
        <Button 
            data-testid="send-email-modal-cancel-btn"
            label={translate("#Core/cancel")} 
            variant="secondary" 
            onClick={handleOnClose} />
        <Button 
            data-testid="send-email-modal-send-btn"
            label={translate("#Core/sendEmail")} 
            onClick={handleOnSend} 
            disabled={isDisabled} />
    </>;

interface SendEmailAddCompanyRecipientsProps {
    allItems: CompanyUserListItem[];
    handleAddButtonOnClick: () => void;
    handleAutoCompleteFieldOnChange: (recipient: Recipient) => void;
}

const SendEmailAddCompanyRecipients = (props: SendEmailAddCompanyRecipientsProps) =>
    <>
        <AutoCompleteField
            data-testid="send-email-user-autocomplete"
            fieldLabel={translate("#Core/findUserToAdd")}
            inputProps={{
                allItems: props.allItems,
                useStringValue: false,
                onChange: ({ value }) => props.handleAutoCompleteFieldOnChange(new Recipient(value.text, value.value))
            }}
        />
        <div className="send-email-container-align-content">
            <Button
                data-testid="send-email-user-add-btn"
                label={translate("#Core/addUser")}
                onClick={props.handleAddButtonOnClick} />
        </div>
    </>;

interface SendEmailAddOtherRecipientsProps {
    handleAddButtonOnClick: () => void;
    handleTextFieldOnChange: (value: Recipient) => void;
}

const SendEmailAddOtherRecipients = (props: SendEmailAddOtherRecipientsProps) =>
    <>
        <TextField
            data-testid="send-email-email"
            fieldLabel={translate("#Core/emailSendTo")}
            fieldDescription={translate("#Core/email")}
            inputProps={{ onChange: ({ value }) => props.handleTextFieldOnChange(new Recipient("", value)) }} />
        <div className="send-email-container-align-content">
            <Button
                data-testid="send-email-email-add-btn"
                label={translate("#Core/add")}
                onClick={props.handleAddButtonOnClick} />
        </div>
    </>;

const SendEmailRecipients = ({ recipients, onRemoveClick }: { recipients: Recipient[], onRemoveClick: (value: string) => void }) =>
    recipients.map((recipient, index) =>
        <React.Fragment key={recipient.email}>
            <Button
                data-testid={`send-email-email-remove-${recipient.email}-btn`}
                key={index}
                variant="inline"
                width="content"
                label={recipient.name === "" ? `${++index}. ${recipient.email}` : `${++index}. ${recipient.name}`}
                onClick={() => onRemoveClick(recipient.email)} />
            <div key={index}></div>
        </React.Fragment>);