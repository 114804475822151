///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CommentsTooltip/CommentsSummaryMessage"/>
import * as React from "react";
import { IMessage, Role } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { FlexLayout, Text } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { StreamErrorMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/StreamErrorMessage";
import { CopilotImage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/images/CopilotImage";

export interface ICommentsSummaryMessageProps {
    message: IMessage,
}

const getClasses = (message:IMessage) => {
    let classes = "copilotCommentsButtons__message";
    
    if(message.content === getLabelTranslation("#Core/agentNSFWWarningMessage")) {
        classes += " mediusAgent__message__nsfwWarning";
    } else {
        classes += " copilotCommentsButtons__message__assistant";
    }
    return classes;
};

const getMarkdownClasses = (message:IMessage) => {
    let classes = "mediusAgent__message__textContainer__markdown";

    if(message.role == Role.assistant) {
        classes += " mediusAgent__message__textContainer__markdown__assistant";
    }

    return classes;
};

const shouldDisplayMessage = (message: IMessage) => {
    return message.content;
};

const LinkRenderer = (props: any) => {
    return <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
    </a>;
};

export function CommentsSummaryMessage({message}: ICommentsSummaryMessageProps) {
    if(message.isError){
        return <StreamErrorMessage retryCallback={null} showRetryButton={false}/>;
    }

    return shouldDisplayMessage(message) &&
    <FlexLayout width="fill">
        <div className={getClasses(message)}>
            {message.content && 
                <ReactMarkdown data-testid="apa-copilot-message-text-content" components={{a: LinkRenderer}} className={getMarkdownClasses(message)} remarkPlugins={[gfm]}>
                    {message.content}
                </ReactMarkdown>
            }

            {message.role === Role.assistant && 
                <FlexLayout direction="row" spacerMode="on" spacerSize="vsmall" className="mediusAgent__message__assistant__footer" data-testid="apa-copilot-assistant-message-footer">
                    <div className="copilot-content-warning" style={{marginRight: "auto", display: "flex", flexDirection: "row"}}>
                        <CopilotImage close-ai-agent-modal="false"/> 
                        <Text variant="small" spacerMode="off">{getLabelTranslation("#Core/SummarizedByMediusCopilot")}&nbsp;{getLabelTranslation("#Core/AIGeneratedContentMayBeIncorrect")}</Text>
                    </div>
                </FlexLayout>
            }
        </div>
    </FlexLayout>;
}