/* eslint-disable react-hooks/exhaustive-deps */
///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/MessageList" />
import * as React from "react";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { AiThinkingIcon } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/AiThinkingIcon";
import { ChatMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/ChatMessage";
import { WelcomeWidget } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/WelcomeWidget";
import { isCopilotPredictedNextApproversEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import { focusOnElement, messageHasAnythingToDisplay, scrollToBottom } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import * as services from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { LoaderIcon } from "@medius/ui-controls";
import { useCopilotContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useCopilotContext";
import * as workflownames from "Core/Medius.Core.Web/Scripts/Medius/workflownames";
import { IMessage, Role } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";

interface IMessageListProps{
    promptInputRef: React.MutableRefObject<any>;
    retryCallback: () => void;
    sendFreetextQuestion: (freetextQuestion: string) => Promise<void>
}

export function MessageList({ promptInputRef, retryCallback, sendFreetextQuestion }: IMessageListProps){
    const { 
        isOperational,
        setMessages,
        messages,
        documentId,
        workflowStepName,
        invoiceType,
        setProcessedMessage,
        isStreamActive,
        setIsAgentAvailable,
        setIsLoadingMessages,
        isLoadingMessages,
        processedMessage,
        isDocumentImport
    } = useCopilotContext();
    
    const [predictedNextApprovers, setPredictedNextApprovers] = React.useState<string[]>();
    const [invoiceInsightsMessageIndex, setInvoiceInsightsMessageIndex] = React.useState<number>();
    const [werePredictedNextApproversUpdated, setWerePredictedNextApproversUpdated] = React.useState(false);
    const [isPredictedNextApproversError, setIsPredictedNextApproversError] = React.useState(false);
    const lastMessageRef = React.useRef(null);

    const getPredictedNextApprovers = async () => {
        try {
            if(isCopilotPredictedNextApproversEnabled() && (
                invoiceType === "Medius.ExpenseInvoice.Entities.ExpenseInvoice" && workflowStepName === workflownames.ApproveDocument ||
                invoiceType === "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice" && workflowStepName === workflownames.AnalyzeDocument ||
                invoiceType === "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice" && workflowStepName === workflownames.AuthorizeDeviations ||
                invoiceType === "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice" && workflowStepName === workflownames.AuthorizeInvoiceAmount
            )){
                const data = await services.getPredictedNextApprovers(documentId, invoiceType, workflowStepName);
                setPredictedNextApprovers(data);
            } else {
                setPredictedNextApprovers([]);
            }
        }
        catch(error) {
            setIsPredictedNextApproversError(true);
        }
    };

    const finishProcessingMessage = () => {
        if(messageHasAnythingToDisplay(processedMessage)) {
            setMessages(prevMessages => [...prevMessages, processedMessage]);
        }
        setProcessedMessage(null);
    };

    const updateInvoiceContextWithPredictedNextApprovers = () => {
        setWerePredictedNextApproversUpdated(true);
        setMessages(messages.map((message, index) => {
            if(invoiceInsightsMessageIndex == index) {
                return {
                    ...message, 
                    invoiceContextDto: {
                        ...message.invoiceContextDto, 
                        predictedNextApprovers: predictedNextApprovers
                    }
                };
            }
            return message;
        }));
    };

    const shouldDisplayFollowupQuestions = (message: IMessage, index: number) => {
        if(message.role !== Role.assistant)
            return false;
        if(messages.length === 1)
            return false;
        if(index !== messages.length - 1)
            return false;
        if(
            message.invoiceContextDto || 
            message.isError || 
            message.nonOperationalInvoiceContextDto ||
            message.supplierWidgetDto ||
            message.content === undefined
        ) return false;

        return true;
    };

    React.useEffect(() => {
        const getAllMessages = async () => {
            try {
                const previousMessages = await services.getMessages();
    
                if (isOperational) {
                    getPredictedNextApprovers();
                }
                
                if(!isDocumentImport) {
                    const invoiceContextMessage = await services.getInvoiceContext(documentId, isOperational);
                    if (invoiceContextMessage) {
                        previousMessages.push(invoiceContextMessage);
                        setInvoiceInsightsMessageIndex(previousMessages.length - 1);
                    }
                }
                setMessages(previousMessages);
            }
            catch (error) {
                handleError(error);
                setIsAgentAvailable(false);
            }
            finally {
                setIsLoadingMessages(false);                
            }
        };

        if (messages.length === 0) {
            getAllMessages();
        }
    }, []);

    React.useEffect(() => {
        if(!werePredictedNextApproversUpdated && (predictedNextApprovers || isPredictedNextApproversError) && messages.length > 0 && invoiceInsightsMessageIndex != undefined) {
            updateInvoiceContextWithPredictedNextApprovers(); 
        }
    }, [invoiceInsightsMessageIndex, predictedNextApprovers, messages, werePredictedNextApproversUpdated, isPredictedNextApproversError]);

    React.useEffect(() => {
        if (processedMessage) {
            if(isStreamActive) {
                scrollToBottom(lastMessageRef);
                return;
            }
            finishProcessingMessage();
        }
    }, [processedMessage, isStreamActive]);

    React.useEffect(() => {
        scrollToBottom(lastMessageRef);
        focusOnElement(promptInputRef);
    }, [messages]);
    
    return (
        <div className="mediusAgent__messagesContainer" data-testid="mediusAgent__messagesContainer">{ isLoadingMessages ? (
            <div className="mediusAgent__innerContainer__loaderIconContainer">
                <LoaderIcon />
            </div> ) : ( <>
            <WelcomeWidget/>
            {messages.map((el, index) => (
                <ChatMessage
                    message={el} 
                    key={index} 
                    invoiceType={invoiceType} 
                    workflowStepName={workflowStepName}
                    retryCallback={retryCallback}
                    showRetryButton={index === messages.length-1}
                    isPredictedNextApproversError={isPredictedNextApproversError}
                    shouldDisplayFollowupQuestions={shouldDisplayFollowupQuestions(el, index)}
                    sendFreetextQuestion={sendFreetextQuestion}
                />
            ))}
            { isStreamActive && !processedMessage?.content && 
                <AiThinkingIcon />
            }
            { messageHasAnythingToDisplay(processedMessage) &&
                <ChatMessage
                    message={processedMessage} 
                    key={messages.length} 
                    invoiceType={invoiceType} 
                    workflowStepName={workflowStepName}
                    retryCallback={retryCallback}
                    showRetryButton={false}
                    isPredictedNextApproversError={isPredictedNextApproversError}
                    shouldDisplayFollowupQuestions={false}
                    sendFreetextQuestion={sendFreetextQuestion}
                />
            }
            <div className="mediusAgent__lastMessageAnchor" ref={lastMessageRef} /></>)}
        </div>
    );
}