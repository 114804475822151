/* eslint-disable react-hooks/exhaustive-deps */
///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/FollowupQuestions" />
import * as React from "react";
import { getFollowupQuestions } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { useCopilotContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useCopilotContext";
import { scrollToBottom } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { Button, LoaderIcon } from "@medius/ui-controls";

interface IFollowupQuestionsProps{
    aiResponse: string;
    sendFreetextQuestion: (freetextQuestion: string) => Promise<void>,
}

export const FollowupQuestions = ({ aiResponse, sendFreetextQuestion }: IFollowupQuestionsProps) => {
    const { isOperational, messages } = useCopilotContext();
    const [followupQuestions, setFollowupQuestions] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const bottomAnchor = React.useRef(null);

    React.useEffect(() => {
        const aiResponseIndex = messages.findIndex(msg => msg.content === aiResponse);
        const userQuestion = messages[aiResponseIndex - 1];
        getFollowupQuestions(userQuestion.content, aiResponse, isOperational).then(fqs => {
            setFollowupQuestions(fqs);
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    React.useEffect(() => {
        if(isLoading == false){
            scrollToBottom(bottomAnchor);
        }
    }, [isLoading])

    return (
        <div className="mediusAgent__followupQuestions__container">
            {isLoading && <div style={{marginRight: "10px"}}><LoaderIcon size="medium" /></div>}
            {followupQuestions.map((question, index) => (
                <div className="mediusAgent__followupQuestions__button" key={index}>
                    <Button label={question} variant="secondary" height="small" onClick={() => sendFreetextQuestion(question) } />
                </div>
            ))}
            <div ref={bottomAnchor}></div>
        </div>
    );
};