/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/userVoice"/>
import { lightApi } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export function open() {
    // It has to be 'synchronous' call, because we are in (probably) ClickHandler,
    // and browser allows open a new tab, or a window by JavaScript only inside it.
    // So, when we would used 'async' call here, a 'done' handler would be executed
    // a little bit later - after ClickHandler end, and browser will block creating
    // a new window, and show only small tooltip: "Popup was blocked"...

    lightApi("remedium-api/RoadmapVoting", "GetForCurrentUser", null, { async: false })
        .fail((jqXhr) => {
            handleAnyError(jqXhr);
        })
        .done((userVoice) => {
            if (userVoice.isAvailable) {
                window.open(userVoice.url, "_blank");     
            } else {
                error(
                    userVoice.userNotEligibleMessage,
                    getLabelTranslation("#Core/userVoiceLink"));
            }
        });
}