define("medius/core/registration", function () {
    "use strict";

    require("Core/Medius.Core.Web/Scripts/Medius/core/customTaskHandlers/abortImportHandler").register();
    require("Core/Medius.Core.Web/Scripts/Medius/models/userSettings/tabs/general").register();
    require("Core/Medius.Core.Web/Scripts/Medius/models/userSettings/tabs/dataSearchConfiguration").register();

    var ko = require("knockout"),
        $ = require("jquery"),
        AvailableTasksModal = require("Core/Medius.Core.Web/Scripts/components/availableTasksModal/availableTasksModal").getInstance;

    function register() {
        require("Core/Medius.Core.Web/Scripts/Medius/extensions/jquery/outerHtml");
        require("Core/Medius.Core.Web/Scripts/appInsights").initializeApplicationInsights();
        require("Core/Medius.Core.Web/Scripts/eventBus").start();
        require("Core/Medius.Core.Web/Scripts/Medius/lib/detector").setUserAgent(window.navigator.userAgent);

        require("Core/Medius.Core.Web/Scripts/lib/globalization").init();
        require("Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider").init();
        require("Core/Medius.Core.Web/Scripts/Medius/core/authorizationKeys").init();
        require("Core/Medius.Core.Web/Scripts/Medius/core/type").preload();
        require("Core/Medius.Core.Web/Scripts/Medius/core/das").preload();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/task/bindings/taskContext").register();
        require("Core/Medius.Core.Web/Scripts/Medius/core/comments/register/knockout").register();
        require("Core/Medius.Core.Web/Scripts/Medius/core/errors/register/knockout").register();
        require("Core/Medius.Core.Web/Scripts/Medius/components/components/bindings").register();
        require("Core/Medius.Core.Web/Scripts/Medius/components/presenters/bindings").register();
        require("Core/Medius.Core.Web/Scripts/Medius/components/editors/bindings");
        require("Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/configurationPropertyBinding");
        require("Core/Medius.Core.Web/Scripts/Medius/components/wizard/bindings/wizard").registerWizardBinding();
        require("Core/Medius.Core.Web/Scripts/Medius/components/generic/bindings").registerBidings();
        require("Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings").registerGridBindings();
        require("Core/Medius.Core.Web/Scripts/Medius/components/validation/bindings").registerBindings();
        require("Core/Medius.Core.Web/Scripts/Medius/components/bindings/componentsContext").registerComponentsContextBinding();
        require("Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/binding").registerEditDocumentSearchQueryBinding();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/withProperties").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/withSpaApp").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/bindings/sortableGadgets").register();
        require("Core/Medius.Core.Web/Scripts/bindings/counter/init").register();
        require("Core/Medius.Core.Web/Scripts/bindings/counter/setup").register();
        require("Core/Medius.Core.Web/Scripts/bindings/counter/attach").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/clickOutsideElem").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/scrollDown").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/snapToWindow").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/translate").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/document/bindings/showModal").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/document/bindings/withModule").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/document/models/HashFiles/withHashFilesModule").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/bindings/withEntityCtxModule").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/bindings/withBulkAdminModule").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/bindings/timepicker").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/bindings").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/autofocus").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/chosenPlugin").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/chosenPluginForLabels").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/codeMirrorPlugin").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/delayedClick").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/drag_drop").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/error").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/htmlValue").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/jscroll").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/longExportConfirmer").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/modal").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/onKey").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/preloader").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/readonlyView").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/resizableAppColumns").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/shortcut").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/sortable").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/wrapTaskTabs").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/scrollable").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/with/withBulkModule").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/asyncTemplate").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/performance").register();
        require("Core/Medius.Core.Web/Scripts/core/attachments/binding/attachments").register();
        require("Core/Medius.Core.Web/Scripts/Medius/core/history/binding/workflowHistory").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/dblclick").register();
        require("Core/Medius.Core.Web/Scripts/Medius/knockout/extenders/numeric/integer").addIntegerKnockoutExtender();
        require("medius/knockout/templateEngines/underscore");
        require("Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/register").register();
        require("Core/Medius.Core.Web/Scripts/Models/userTask").register();
        require("Core/Medius.Core.Web/Scripts/Models/document").registerMapping();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.AsyncTask.AsyncTaskInformation").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsConfiguration").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsConfigurationWithList").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsConfigurationWithListAndTextArea").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.DocumentRegistration.Draft").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.NewInbox.TaskTab").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.ReminderDetails").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.ReviewTask").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.StartTask").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.TaskResult.TaskResult").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.TaskResult.CodeResult").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.ErrorHandling.Error").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsContainer").registerMapping();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsWithListContainer").registerMapping();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsWithListAndTextAreaContainer").registerMapping();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/viewModelExtensions/roleDataObjectAutohorization").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.ReportStuckIntegrationMessagesJobContext/Editor").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.IntegrationMessageCleanupJobContext/Editor").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.MasterDataCleanupJobContext/Editor").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.CleanupJobContext/Editor").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.ImportJobContext/Editor");
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Schedulers.ScheduledJobContext/Editor").register();
        require("Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/register").register();
        require("Core/Medius.Core.Web/Scripts/Medius/core/shortcuts/definitions").registerDefinitions();
        require("Core/Medius.Core.Web/Scripts/Medius/core/cloudNotifications/binding/notificationBinding").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/framework").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/culture");
        require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/bindings/withEntityCtxTypeScriptModule").registerWithEntityCtxTypeScriptBinding();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoSplitter").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/defaultModal").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoDialog").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoDatePicker").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoTimePicker").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoGrid").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoTreeView").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoAutoComplete").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoDropDownList").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoNumericTextBox").register();
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoMultiSelect");
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoListBox");
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoListView");
        require("Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoConnectedLists");
        require("Core/Medius.Core.Web/Scripts/Medius/moment/culture");
        require("Core/Medius.Core.Web/Scripts/components/mention/editor/bindings/cursorPosition");
        require("Core/Medius.Core.Web/Scripts/Models/Dtos/companyAutocompleterDto").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/workflow/studio/businessRuleEditor").register();
        require("Core/Medius.Core.Web/Scripts/components/pdfViewer/bindings/imageViewerContainer").register();
        require("Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/bindings/autoScroll").register();
        require("Core/Medius.Core.Web/Scripts/Models/amount").register();
        require("Core/Medius.Core.Web/Scripts/shared/bindings/highlightedText");
        require("Core/Medius.Core.Web/Scripts/shared/bindings/intermediateCheckbox");
        require("Core/Medius.Core.Web/Scripts/shared/bindings/importantVisible").registerBinding();
        require("Core/Medius.Core.Web/Scripts/components/controls/multiSelect");
        require("Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/currency/select-currency").register();
        require("Core/Medius.Core.Web/Scripts/AdminPages/registerCustomActions").register();
        require("Core/Medius.Core.Web/Scripts/AdminPages/AdministrationFavouriteLink/General/hasCustomizedTranslationsDialogBinding").register();
        require("Core/Medius.Core.Web/Scripts/AdminPages/Company/General/onInactivateDialogBinding").register();
        require("Core/Medius.Core.Web/Scripts/Medius/core/labels/labelsRepository").init();
        require("Core/Medius.Core.Web/Scripts/bindings/kendoButton").register();
        require("Core/Medius.Core.Web/Scripts/bindings/userAutocompleter").register();
        require("Core/Medius.Core.Web/Scripts/lib/reactIntegration/reactBinding").register();
        require("Core/Medius.Core.Web/Scripts/lib/reactIntegration/reactTaskTabsBinding").register();
        require("Core/Medius.Core.Web/Scripts/AdminPages/registerCustomGrids").register();
        require("Core/Medius.Core.Web/Scripts/AdminPages/PasswordManagement/passwordManagement").registerPasswordManagementAdminPage();
        require("Core/Medius.Core.Web/Scripts/AdminPages/DocumentManagement/documentManagement").registerDocumentManagementAdminPage();
        require("Core/Medius.Core.Web/Scripts/AdminPages/ResolutionConfiguration/resolutionConfiguration").registerResolutionConfigurationAdminPage();
        require("Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/importManagementPage").registerImportManagementAdminPage();
        require("Core/Medius.Core.Web/Scripts/AdminPages/ImportConfiguration/importConfigurationPage").registerImportConfigurationAdminPage();
        require("Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/authorizationGroups").registerAuthorizationGroupsAdminPage();
        require("Core/Medius.Core.Web/Scripts/AdminPages/DocumentCacheManagement/documentCacheManagement").registerDocumentCacheManagementAdminPage();
        require("Core/Medius.Core.Web/Scripts/AdminPages/MasterDataReindex/masterDataReindex").registerMasterDataReindexAdminPage();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Integration.EDIDocumentImport").register();
        require("Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.EndTask").register();
        require("Core/Medius.Core.Web/Scripts/Models/supplierInvoiceInEdi").registerMapping();
        require("Core/Medius.Core.Web/Scripts/Models/Entities/Customfields/CustomFieldsPerspective");
        require("Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/paymentDetails").register();
        require("Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/paymentTerm").register();
        require("Core/Medius.Core.Web/Scripts/AdminPages/PaymentTerm/extensionRegistration").register();
        require("core").init();
        
        $(document).ready(function () {
            require("Core/Medius.Core.Web/Scripts/components/topBar/topBarContainerViewModel").register();

            require("Core/Medius.Core.Web/Scripts/Medius/session/register").init();
            require("Core/Medius.Core.Web/Scripts/Medius/core/cloudNotifications/notification").create().init();

            var availableTasksModal = AvailableTasksModal();
            ko.applyBindings(availableTasksModal, document.getElementById('available-tasks-modal'));
            ko.applyBindings(null, document.getElementById('report-context-menu-template'));

            //shortcuts modal registration
            require("Core/Medius.Core.Web/Scripts/components/shortcutsModal/shortcutsModal").register();
            require("Core/Medius.Core.Web/Scripts/Medius/passwordExpiringNotification").notifyAboutExpiringPassword();
        });
    }

    return { register: register };
});
