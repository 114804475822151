///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CommentsTooltip/CopilotCommentsTooltip" />
import * as React from "react";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { CopilotButton } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotButton";
import { MediusCopilotInteractionType, trackInteractionEvent } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/CopilotAppInsights";
import { sendCommentsSummaryRequestAndStreamResponse } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { ICommentToSummarize, IMessage, Role } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { TimeoutErrorMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/TimeoutErrorMessage";
import { messageHasAnythingToDisplay } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { AiThinkingIcon } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/AiThinkingIcon";
import { CommentsSummaryMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CommentsTooltip/CommentsSummaryMessage";

interface ICopilotCommentsTooltipProps {
    documentId: number;
    comments: ICommentToSummarize[];
}

export function CopilotCommentsTooltip({ documentId, comments }: ICopilotCommentsTooltipProps) {
    const [isStreamActive, setIsStreamActive] = React.useState(false);
    const [isTimeoutError, setIsTimeoutError] = React.useState(false);
    const [processedMessage, setProcessedMessage] = React.useState<IMessage>();


    const handleStreamEnd = () => {
        setIsStreamActive(false);
    };

    const addErrorMessage = () => {
        setProcessedMessage({
            isError: true,
            content: "",
            role: Role.assistant,
            conversationId: null,
            documentId: documentId,
            date: new Date().toISOString(),
            sources: [],
            taskId: null
        });
    };

    const handleMessageChunk = (message: IMessage) => {
        setProcessedMessage({
            ...message,
            content: getLabelTranslation(message.content),
            role: Role.assistant,
            conversationId: null,
            documentId: documentId,
            taskId: null,
        });
    };

    const sendChatRequest = async (
    ) => {
        setIsStreamActive(true);
        setIsTimeoutError(false);
        setProcessedMessage(null);

        const abortControllerInstance = new AbortController();

        const timeout = setTimeout(() => {
            abortControllerInstance.abort();
            handleStreamEnd();
            setIsTimeoutError(true);
        }, 60000);

        try {
            await sendCommentsSummaryRequestAndStreamResponse(
                documentId,
                comments,
                handleMessageChunk,
                handleStreamEnd,
                abortControllerInstance.signal,
                timeout,
            );
        } catch (error) { addErrorMessage(); }
    };

    return (<>
        <div className="copilotCommentsButtons__buttons">
            <CopilotButton 
                close-ai-agent-modal="false"
                isMainCopilotButton={false} 
                data-testid="" 
                label={getLabelTranslation("#Core/SummarizeDocumentComments")} 
                onClick={() => {
                    trackInteractionEvent(MediusCopilotInteractionType.CommentsTooltipButtonClick);
                    sendChatRequest();
                }}
            />
        </div>
        {(processedMessage || isStreamActive || isTimeoutError) && 
            <div className="copilotCommentsButtons__answerContainer">
                <div className="copilotCommentsButtons__answerContainerInner">
                { isTimeoutError && <TimeoutErrorMessage/> }
                { (isStreamActive && !processedMessage?.content) && 
                    <div className="copilotCommentsButtons__loader">
                        <AiThinkingIcon />
                    </div>
                }
                { messageHasAnythingToDisplay(processedMessage) &&
                    <CommentsSummaryMessage
                        message={processedMessage} 
                    />
                }
                </div>
            </div>
        }
    </>);
}